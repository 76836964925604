#header {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--bg-100);
  padding: 1rem;
  margin-bottom: 1rem;
}

header a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

header h1 {
  padding: 0.5rem 0 0 1rem;
  margin: 0;
}

header #menu-btn {
  margin: 0 0 0 0.5rem;
  z-index: 12;
}

header #search-btn {
  margin: 0 0 0 auto;
}

header #dad-jokes-logo svg,
header #menu-btn svg {
  width: 40px;
  height: 40px;
  stroke: var(--primary-100);
  fill: var(--primary-100);
}

header #search-btn > svg {
  width: 28px;
  height: 28px;
  stroke: var(--primary-100);
  fill: none;
}

/* NAV FLYOUT */

header nav {
  display: flex;
}

.flyout {
  position: absolute;
  width: 100%;
  right: 0;
  top: 80px;
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  visibility: hidden;
  opacity: 0;
  z-index: 11;
  transition: all 0.2s ease;
}

.flyout.show {
  top: 88px;
  visibility: visible;
  opacity: 1;
}

.flyout .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: var(--bg-100);
  opacity: 0.75;
  z-index: 10;
}

.flyout-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  max-width: 260px;
  padding: 1.25rem;
  border: 1px solid var(--primary-100);
  border-radius: 0.5rem;
  background: var(--bg-200);
  z-index: 11;
}

.flyout-content .primary-color-btn,
.flyout-content .nav-page-btn {
  font-size: 1.25rem;
  display: flex;
  justify-content: flex-start;
  padding: 0.75rem;
  margin: 0;
  background: var(--bg-100);
  border: 1px solid transparent;
  border-radius: 0.5rem;
}

.nav-page-btn span {
  padding: 0.25rem 0 0;
  margin-left: 0.75rem;
}

.nav-page-btn > svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--primary-100);
}

.flyout-content button:hover,
.flyout-content button:active,
.flyout-content a:hover,
.flyout-content a:active {
  border: 1px solid var(--primary-100);
}

/* PRIMARY COLORS */

#nav-menu .primary-color-btn-wrap {
  display: flex;
  justify-content: center;
  padding: 0.375rem 0;
  background: var(--bg-100);
  border-radius: 0.5rem;
}

#nav-menu .primary-color-btn {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: clip;
}

#nav-menu .primary-color-btn > span {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

#nav-menu .primary-color-btn:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  width: 2px;
  background: var(--bg-200);
}

span.green {
  background-color: var(--primary-green-100);
}

span.blue {
  background-color: var(--primary-blue-100);
}

span.purple {
  background-color: var(--primary-purple-100);
}

span.orange {
  background-color: var(--primary-orange-100);
}

/* MENU BUTTON SVG + ANIMATION */

.line {
  fill: none;
  stroke-width: 9;
  transition: stroke-dasharray 200ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 9;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 9;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 9;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 9;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 9;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 9;
}
